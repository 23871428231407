<template>
  <b-row class="no-gutters mx-auto justify-content-center mt-5 pb-5 px-3">
    <b-col class="col-12 col-lg-8 text-center">
      <h2 class="mb-5 font-weight-bold text-uppercase text-red">
        {{ $t("page.projects.createNewProject") }}
      </h2>
      <b-row class="no-gutters justify-content-center">
        <b-col class="col-12 col-md-5 col-xl-4 pr-md-2">
          <b-card
            no-body
            class="h-100"
            footer-bg-variant="white"
            footer-class="border-top-0"
          >
            <b-card-text class="text-white bg-blue-light h-100">
              <b-row align-v="end" class="no-gutters h-100 pt-4 pt-md">
                <b-col class="col-12">
                  <h4 class="mb-0 text-uppercase product">
                    {{ $t("projects.discover") }}
                  </h4>
                  <p class="product__label">
                    {{ $t("page.newProject.testVideo") }}
                  </p>
                  <b-row
                    align-v="start"
                    class="no-gutters justify-content-center"
                  >
                    <b-col class="col-auto">
                      <h3 class="font-weight-bold mb-0 mt-2 mr-1">&euro;</h3>
                    </b-col>
                    <b-col class="col-auto">
                      <h1 class="price mb-0">
                        {{ discoverPackage.defaultPrice | price }}
                      </h1>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-text>
            <template v-slot:footer>
              <div class="pb-4">
                <div class="px-3 pb-3">
                  <span class="description__item">
                    {{ $t("page.newProject.oneVideo") }}
                  </span>
                  <hr class="divider my-2" />
                  <span class="description__item">
                    {{
                      $t("page.newProject.oneVideoMaxTime", {
                        time: discoverPackage.maxMinute
                      })
                    }}
                  </span>
                  <hr class="divider my-2" />
                  <span class="description__item">{{
                    $t("page.newProject.maxRespondents", {
                      total: discoverPackage.totalRespondents
                    })
                  }}</span>
                  <hr class="divider my-2" />
                  <span class="description__item">
                    {{ $t("page.newProject.countrySelection") }}
                  </span>
                </div>
                <b-button
                  variant="red"
                  class="mt-3"
                  block
                  pill
                  @click="openModal(utils.PROJECT_PACKAGE.DISCOVER)"
                >
                  <span class="h4 text-uppercase">{{
                    $t("button.start")
                  }}</span>
                </b-button>
              </div>
            </template>
          </b-card>
        </b-col>
        <!-- <b-col class="col-12 col-md-5 col-xl-4 pl-md-2 mt-3 mt-md-0">
          <b-card
            no-body
            class="h-100"
            footer-bg-variant="white"
            footer-class="border-top-0"
          >
            <b-card-text class="text-white bg-blue">
              <b-row class="no-gutters p-2 justify-content-end text-uppercase">
                <div class="bg-red offer__label p-2">
                  {{ $t("page.newProject.mostPopular") }}
                </div>
              </b-row>
              <b-row align-v="end" class="no-gutters card__header mt-2">
                <b-col class="col-12">
                  <h4 class="mb-0 product text-uppercase">
                    {{ $t("projects.abtest") }}
                  </h4>
                  <p class="product__label">
                    {{ $t("page.newProject.discoverBestPerformance") }}
                  </p>
                  <b-row
                    align-v="start"
                    class="no-gutters justify-content-center"
                  >
                    <b-col class="col-auto">
                      <h3 class="font-weight-bold mb-0 mt-2 mr-1">&euro;</h3>
                    </b-col>
                    <b-col class="col-auto">
                      <h1 class="price mb-0">
                        {{ testPackage.defaultPrice | price }}
                      </h1>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-text>
            <template v-slot:footer>
              <div class="pb-4">
                <div class="px-3 pb-3">
                  <span class="description__item">
                    {{
                      $t("page.newProject.manyVideos", {
                        total: testPackage.totalVideo
                      })
                    }}
                  </span>
                  <hr class="divider my-2" />
                  <span class="description__item">
                    {{
                      $t("page.newProject.manyVideosMaxTime", {
                        time: testPackage.maxMinute
                      })
                    }}
                  </span>
                  <hr class="divider my-2" />
                  <span class="description__item">{{
                    $t("page.newProject.maxRespondents", {
                      total: testPackage.totalRespondents
                    })
                  }}</span>
                  <hr class="divider my-2" />
                  <span class="description__item">
                    {{ $t("page.newProject.countrySelection") }}
                  </span>
                </div>
                <b-button
                  variant="red"
                  class="mt-3"
                  block
                  pill
                  @click="openModal(utils.PROJECT_PACKAGE.TEST)"
                >
                  <span class="h4 text-uppercase">{{
                    $t("button.start")
                  }}</span>
                </b-button>
              </div>
            </template>
          </b-card>
        </b-col> -->

        <b-col class="col-12 col-md-5 col-xl-4 pl-md-2 mt-3 mt-md-0">
          <b-card
            no-body
            class="h-100"
            footer-bg-variant="white"
            footer-class="border-top-0"
          >
            <b-card-text class="text-white bg-blue">
              <b-row class="no-gutters p-2 justify-content-end text-uppercase">
                <div class="bg-red offer__label p-2">
                  {{ $t("page.newProject.mostPopular") }}
                </div>
              </b-row>
              <b-row align-v="end" class="no-gutters card__header mt-2">
                <b-col class="col-12">
                  <h4 class="mb-0 product text-uppercase">
                    {{ $t("projects.sequential") }}
                  </h4>
                  <p class="product__label">
                    {{ $t("page.newProject.discoverBestPerformance") }}
                  </p>
                  <b-row
                    align-v="start"
                    class="no-gutters justify-content-center"
                  >
                    <b-col class="col-auto">
                      <h3 class="font-weight-bold mb-0 mt-2 mr-1">&euro;</h3>
                    </b-col>
                    <b-col class="col-auto">
                      <h1 class="price mb-0">
                        {{ abtestSequentialPackage.defaultPrice | price }}
                      </h1>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-text>
            <template v-slot:footer>
              <div class="pb-4">
                <div class="px-3 pb-3">
                  <span class="description__item">
                    {{
                      $t("page.newProject.manyVideos", {
                        total: abtestSequentialPackage.totalVideo
                      })
                    }}
                  </span>
                  <hr class="divider my-2" />
                  <span class="description__item">
                    {{
                      $t("page.newProject.manyVideosMaxTime", {
                        time: abtestSequentialPackage.maxMinute
                      })
                    }}
                  </span>
                  <hr class="divider my-2" />
                  <span class="description__item">{{
                    $t("page.newProject.maxRespondents", {
                      total: abtestSequentialPackage.totalRespondents
                    })
                  }}</span>
                  <hr class="divider my-2" />
                  <span class="description__item">
                    {{ $t("page.newProject.countrySelection") }}
                  </span>
                </div>
                <b-button
                  variant="red"
                  class="mt-3"
                  block
                  pill
                  @click="openModal(utils.PROJECT_PACKAGE.ABTEST_SEQUENTIAL)"
                >
                  <span class="h4 text-uppercase">{{
                    $t("button.start")
                  }}</span>
                </b-button>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <new-project-modal
      :modalStatus="modalStatus"
      :projectName="projectName"
      :projectType="projectType"
      :branchList="branchList"
      :projectBranchId="projectBranchId"
      @setModalStatus="setModalStatus"
      @setProjectName="setProjectName"
      @setProjectBranchId="setProjectBranchId"
      @submit="checkEnv"
    />
  </b-row>
</template>

<script>
import Utils from "./../Utils";
import dayjs from "dayjs";

import NewProjectModal from "./../components/NewProject/NewProjectModal";
export default {
  components: {
    NewProjectModal,
  },
  data() {
    return {
      modalStatus: false,
      projectName: "",
      projectBranchId: "",
      projectType: "",
      utils: Utils,
    };
  },
  computed: {
    userType() {
      return this.$store.getters["auth/getUserType"];
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    branchList() {
      // if custom multiuser admin users return branch list
      if (
        this.userType === "custom" &&
        this.isMultiUser &&
        this.userSubType === "admin"
      ) {
        return this.$store.getters["multiuser/getBranches"];
      }
      return [];
    },
    projectPackageList() {
      return this.$store.getters["wizard/getProjectPackageList"];
    },
    discoverPackage() {
      return this.projectPackageList[Utils.PROJECT_PACKAGE.DISCOVER];
    },
    testPackage() {
      return this.projectPackageList[Utils.PROJECT_PACKAGE.TEST];
    },
    abtestSequentialPackage() {
      return this.projectPackageList[Utils.PROJECT_PACKAGE.ABTEST_SEQUENTIAL];
    },
    audienceProjectCategories() {
      return this.$store.getters["audience/getBrandCategoryOptions"];
    },
  },
  methods: {
    openModal(projectType) {
      if (
        projectType !== Utils.PROJECT_PACKAGE.DISCOVER &&
        projectType !== Utils.PROJECT_PACKAGE.TEST &&
        projectType !== Utils.PROJECT_PACKAGE.ABTEST_SEQUENTIAL
      ) {
        console.log("Selected package not supported");
        return;
      }
      this.modalStatus = true;
      this.projectType = projectType;
      this.projectName =
        this.$t(`projects.${projectType}`).toUpperCase() +
        " - " +
        this.$t("page.projects.newProject") +
        ` ${dayjs().format("DD/MM/YYYY, HH:mm:ss")}`;
    },
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    setProjectName(projectName) {
      this.projectName = projectName;
    },
    setProjectBranchId(projectBranchId) {
      this.projectBranchId = projectBranchId;
    },
    async checkEnv() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        if (this.audienceProjectCategories.length === 0) {
          await this.$store.dispatch(
            "audience/retrieveProjectCategories",
            false
          );
        }
        this.createProject();
      } catch {
        this.$store.commit("error/SET_ERROR", {
          messageText: "page.newProject.error.createProject.message"
        });
      } finally {
        this.$store.dispatch("loader/close");
      }
    },
    createProject() {
      this.$store.commit("loader/SET_LOADER", {});

      this.$store.commit("wizard/SET_ACTIVE_PROJECT_NAME", this.projectName);
      this.$store.commit("wizard/SET_ACTIVE_PROJECT_PACKAGE", this.projectType);

      this.$store.commit("audience/RESET");
      this.$store.commit("checkout/RESET");

      this.$store
        .dispatch("project/createProjectByClientId", this.projectBranchId)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
  },
};
</script>

<style scoped>
.product {
  font-size: 24px;
  font-weight: 700;
}
.product__label {
  font-size: 13px;
  font-weight: 400;
}
.price {
  font-size: 55px;
  font-weight: 900;
}
.offer__label {
  font-size: 10px;
  font-weight: normal;
  border-radius: 5px;
}
.description__item {
  font-size: 15px;
  font-weight: 400;
}
.card__header {
  min-height: calc(100% - 31px - 16px - 8px);
}
</style>
